// this is largely taken from flexsearch source code
// had to add support for 'æ', 'ð' and 'þ'
const regex = str => {
  return new RegExp(str, 'g')
}

const replace = (str, regexp) => {
  for (let i = 0; i < regexp.length; i += 2) {
    str = str.replace(regexp[i], regexp[i + 1])
  }
  return str
}

// strip property names from stringified object
exports.removeProps = str => {
  try {
    const indexedProps = JSON.parse(str)
    return Object.values(indexedProps).reduce((prev, current) => {
      if (current) {
        return `${prev} ${current}`
      }
      return prev
    }, '')
  } catch {
    return str
  }
}

exports.encodeString = str => {
  if (typeof str === 'undefined') {
    return
  }

  const regex_whitespace = regex('\\s+'),
    regex_strip = regex('[^a-z0-9 ]'),
    regex_space = regex('[-/]'),
    regex_a = regex('[àáâãäå]'),
    regex_e = regex('[èéêë]'),
    regex_i = regex('[ìíîï]'),
    regex_o = regex('[òóôõöő]'),
    regex_u = regex('[ùúûüű]'),
    regex_y = regex('[ýŷÿ]'),
    regex_n = regex('ñ'),
    regex_c = regex('[çc]'),
    regex_s = regex('ß'),
    regex_t = regex('þ'),
    regex_ae = regex('æ'),
    regex_d = regex('ð'),
    regex_and = regex(' & ')

  const regex_pairs = [
    regex_a,
    'a',
    regex_ae,
    'ae',
    regex_e,
    'e',
    regex_i,
    'i',
    regex_o,
    'o',
    regex_u,
    'u',
    regex_y,
    'y',
    regex_n,
    'n',
    regex_c,
    'k',
    regex_s,
    's',
    regex_t,
    't',
    regex_d,
    'd',
    regex_and,
    ' and ',
    regex_space,
    ' ',
    regex_strip,
    '',
    regex_whitespace,
    ' ',
  ]

  return replace(str.toLowerCase(), regex_pairs) || ''
}
