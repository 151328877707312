import React, { MouseEventHandler, useMemo, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import { useQueryParam, StringParam } from 'use-query-params'
import { useFlexSearch } from 'react-use-flexsearch'
import { truncate } from 'lodash'
import { Link } from '@cmp'
import { MainLayout, useLanguage, withLanguageProvider } from '@tmp'
import { Icon, InfoRow, Text, Hyphen } from '@src/components'
import { sortResultsByWeight } from '@src/utils'
import { allLocales } from '@src/utils/locale'
import { LocalizedRoute, makePath } from '@src/utils/routes'
import { GetResultPageQuery } from '@gql-types'
import cn from 'classnames'
import moment from 'moment'
import { sortResultsByFirstDate } from '@src/utils/helpers'
const searchEncoder = require('../../../searchencoder')
const encodeString = searchEncoder.encodeString

const VISIBLE_RESULTS = 8

const ShowMoreButton = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => (
  <button onClick={onClick} className='flex items-center justify-center w-full pb-6 my-6 border-b-2 border-black'>
    <Text weight='bold'>Fleira</Text>
    <Icon name='Arrow' className='w-4 h-auto ml-4 transform rotate-45' />
  </button>
)

const SearchResultsTemplate = ({ data }: PageProps<GetResultPageQuery>) => {
  const [query] = useQueryParam('term', StringParam)
  const [pagesVisible, setPagesVisible] = useState(VISIBLE_RESULTS)
  const [eventsVisible, setEventsVisible] = useState(VISIBLE_RESULTS)
  const [newsVisible, setNewsVisible] = useState(VISIBLE_RESULTS)
  const [staffVisible, setStaffVisible] = useState(VISIBLE_RESULTS)
  const { locale, t } = useLanguage()
  const localizedUrls: LocalizedRoute[] = allLocales.map(locale => ({ locale, route: { type: 'search' } }))

  // this should really be handled by the library, but we need to encode the searchquery
  // to get appropiate results
  const searchableTerm: string = encodeString(query) ?? ''

  const eventIndex = data?.localSearchEvents?.index
  const eventStore = data?.localSearchEvents?.store
  const eventResults = useFlexSearch(searchableTerm, eventIndex, eventStore)
  const sortedEventResults = useMemo(() => sortResultsByFirstDate(eventResults), [eventResults])

  const pageIndex = data?.localSearchPage?.index
  const pageStore = data?.localSearchPage?.store
  const pageResults = useFlexSearch(searchableTerm, pageIndex, pageStore).filter(
    (page: any) => page.locale === locale,
  )
  const sortedPageResults = useMemo(() => sortResultsByWeight(pageResults, query ?? '', 'name'), [pageResults])

  const newsResults = sortedPageResults.filter(item => item.category === 'ContentfulNewsArticle')
  const filteredPageResults = sortedPageResults.filter(
    item => item.category === 'ContentfulPage' || item.category === 'ContentfulVenuePage',
  )
  const staffResults = sortedPageResults.filter(item => item.category === 'ContentfulStaffMember')

  if (!sortedEventResults?.length && !sortedPageResults.length) {
    return (
      <MainLayout localizedUrls={localizedUrls}>
        <Text as='h3' variant='h3' className='gutter pt-36 md:pt-6'>
          {t('no-search-results')}
        </Text>
      </MainLayout>
    )
  }

  const getPagePath = (page: any) => {
    if (page.category === 'ContentfulNewsArticle') {
      return makePath(locale, { type: 'newsItem', slug: page.slug })
    }
    if (page.category === 'ContentfulStaffMember') {
      return makePath(locale, { type: 'staff' })
    }
    return makePath(locale, { type: 'page', slug: page.slug, parentSlug: page.parentSlug })
  }

  return (
    <MainLayout localizedUrls={localizedUrls}>
      <Text as='h1' variant='h2' weight='heavy' className='mb-16 gutter pt-28 md:pt-12 capitalize-first'>
        <Hyphen>
          {t('search-results') + '(' + (sortedPageResults.length + sortedEventResults.length) + ')' ?? ''}
        </Hyphen>
      </Text>

      {sortedEventResults?.length ? (
        <div className='w-full'>
          <Text as='h3' variant='h3Alt' className='pb-3 border-b gutter capitalize-first border-dark-400'>
            {t('events') + '(' + sortedEventResults.length + ')'}
          </Text>

          {sortedEventResults.length ? (
            <div className='w-full'>
              {sortedEventResults.slice(0, eventsVisible).map(page => {
                const description = truncate(page.descriptionText, { length: 154, separator: ' ' })

                return (
                  <Link key={page.id} noStyle to={`${getPagePath(page)}`}>
                    <div
                      className={cn(
                        'md:grid grid-cols-12 group py-6 px-6 items-center border-b border-black hover:bg-yellow-100',
                      )}
                    >
                      <div
                        className={cn(
                          'pb-2 md:pb-0 md:px-0 col-span-12 md:col-span-2 px-4',
                        )}
                      >
                        <img src={page.featuredImage?.childImageSharp?.resize?.src ?? ''} />
                      </div>
                      <div className={cn('pb-2 md:pb-0 col-span-4 gutter-l first:md:gutter-l flex h-full')}>
                        <Text
                          variant='h3'
                          weight='bold'
                        >
                          {locale === 'is' ? page.name : page.enName ?? page.name}
                        </Text>
                      </div>
                      <div className='relative col-span-5 flex h-full'>
                        <Text variant='medium' className={cn('px-4', { 'md:mr-8 md:pr0': !page.detail })}>
                          {description}
                        </Text>
                      </div>
                      <div className='col-span-1 flex justify-start flex-col md:items-center h-full pt-3 md:pt-0'>
                        {page.dates?.map((date) => {
                          return (
                            <Text variant='medium' className={cn('px-4', { 'md:mr-8 md:pr0': !page.detail })}>
                              {moment(date.startDate).format('DD.MM.YYYY')}
                            </Text>
                          )
                        })}
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          ) : null}
        </div>
      ) : null}
      {eventResults.length > eventsVisible && (
        <ShowMoreButton onClick={() => setEventsVisible(eventsVisible + VISIBLE_RESULTS)} />
      )}

      {filteredPageResults.length ? (
        <div className='w-full pt-20'>
          <Text as='h3' variant='h3Alt' className='pb-3 border-b gutter capitalize-first border-dark-400'>
            {t('pages') + '(' + filteredPageResults.length + ')'}
          </Text>

          {filteredPageResults.slice(0, pagesVisible).map(page => {
            const description = truncate(page.description, { length: 154, separator: ' ' })
            return (
              <Link key={page.id} noStyle to={`${getPagePath(page)}`} className='block hover:bg-yellow-100'>
                <InfoRow
                  description={description}
                  image={page.image}
                  title={page.name}
                  columnLayout='grid-cols-searchResults'
                />
              </Link>
            )
          })}
        </div>
      ) : null}

      {filteredPageResults.length > pagesVisible && (
        <ShowMoreButton onClick={() => setPagesVisible(pagesVisible + VISIBLE_RESULTS)} />
      )}

      {newsResults.length ? (
        <div className='w-full pt-20'>
          <Text as='h3' variant='h3Alt' className='pb-3 border-b gutter capitalize-first border-dark-400'>
            {t('news') + '(' + newsResults.length + ')'}
          </Text>

          {newsResults.slice(0, newsVisible).map(page => {
            const description = truncate(page.description, { length: 154, separator: ' ' })
            return (
              <Link key={page.id} noStyle to={`${getPagePath(page)}`} className='block hover:bg-yellow-100'>
                <InfoRow
                  description={description}
                  image={page.image}
                  title={page.name}
                  columnLayout='grid-cols-searchResults'
                />
              </Link>
            )
          })}
        </div>
      ) : null}
      {newsResults.length > newsVisible && (
        <ShowMoreButton onClick={() => setNewsVisible(newsVisible + VISIBLE_RESULTS)} />
      )}

      {staffResults.length ? (
        <div className='w-full pt-20'>
          <Text as='h3' variant='h3Alt' className='pb-3 border-b gutter capitalize-first border-dark-400'>
            {t('staff') + '(' + staffResults.length + ')'}
          </Text>

          {staffResults.slice(0, staffVisible).map(page => {
            const description = truncate(page.description, { length: 154, separator: ' ' })
            return (
              <Link key={page.id} noStyle to={'mailto:' + page.email} className='block hover:bg-yellow-100'>
                <InfoRow
                  detail={page.phoneNumber}
                  email={page.email}
                  description={description}
                  image={page.image}
                  title={page.name}
                  columnLayout='grid-cols-searchStaffResults'
                />
              </Link>
            )
          })}
        </div>
      ) : null}

      {staffResults.length > pagesVisible && (
        <ShowMoreButton onClick={() => setStaffVisible(staffVisible + VISIBLE_RESULTS)} />
      )}
    </MainLayout>
  )
}

export default withLanguageProvider(SearchResultsTemplate)

export const query = graphql`
  query GetResultPage {
    localSearchEvents {
      index
      store
    }
    localSearchPage {
      index
      store
    }
  }
`
